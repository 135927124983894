<template>
  <div>
    <toggleSwitch2
      :title="$t('design.info.text')"
      :help="''"
      :value="data"
      :callback="setToggle"
    />
    <div class="flix-flex flix-gap-10" v-if="data">
      <infoDescription />
      <div>
        <infoButton />
        <infoStartUp />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    infoButton() {
      return import("./button");
    },
    infoDescription() {
      return import("./description");
    },
    infoStartUp() {
      return import("./startup");
    }
  },
  props: {},
  data() {
    return {
      data: this.getValue()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setToggle() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (!this.data) {
        data.info = {
          text: this.$t("design.info.description.default"),
          onStart: true,
          button: this.$t("design.info.button.default")
        };
      } else {
        data.info = false;
      }

      this.$store.commit("business/prefetch", data);
      this.data = !this.data;
    },
    setValue() {},
    getValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (typeof data.info === "undefined" || !data.info) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="sass" scoped></style>
